import * as React from 'react'
import {EditFormBase} from '../../forms';
import Papa from 'papaparse';

import Page from '../../components/Page'
import Container from '../../components/Container'
import IndexLayout from '../../layouts'
import * as Api from '../../api/api';
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";
import {getAuthToken} from "../../authToken";

function parseRow(input: string[]): Api.DirectAlertTableEntity {
	return {
		accountName: input[1],
		accountNumber: input[0],
		zipCode: input[2]
	};
}

interface IExportState extends Api.SearchQuery {
	isLoading: boolean;
	onlyUpdates: boolean;
	exportsRes: Api.ApiResultListExportRecord;
	searchRes: Api.ApiResultApiRegistrations;
}

class AdminExportPage extends React.Component<{}, IExportState> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			minDate: '',
			onlyUpdates: true,
			maxDate: '',
			query: '',
			recordExport: true,
			isLoading: true,
			skip: 0,
			take: 10000,
			exportsRes: {
				value: [],
				stackTrace: [],
				message: '',
				success: false,
			},
			searchRes: {
				value: {
					registrations: [],
					success: false,
					recordsAffected: 0,
				},
				stackTrace: [],
				message: '',
				success: false,
			},
		};
	}

	public componentDidMount() {
		this.setState({
			isLoading: true
		}, () => {
			new Api.DirectAlertApi().getExportHistoryGET({
				authToken: getAuthToken() || undefined,
			}).then((exportsRes) => {
				this.setState({exportsRes});
				if (exportsRes.value && exportsRes.value.length > 0) {
					const first = exportsRes.value[0];
					this.setState({minDate: (first.toDate || new Date()).toString().substr(0, 10)});
				}
				this.setState({isLoading: false});
			}, (err) => {
				console.log('Failed to load', err);
				this.setState({isLoading: false});
			});
		});
	}

	public submitSearch(e) {
		e.preventDefault();
		this.setState({
			isLoading: true
		}, () => {
			new Api.DirectAlertApi().directAlertAdminSearchPOST({
				authToken: getAuthToken() || undefined,
				req: this.state,
			}).then((searchRes) => {
				this.setState({searchRes});
				this.setState({isLoading: false});
			}, (err) => {
				console.log('Failed to load', err);
				this.setState({isLoading: false});
			});
		});
	}

	public render() {
		return (
			<IndexLayout>
				<Page>
					<Container>
						<h1>Export DirectAlert Users</h1>

						<Row>
							<Col sm={6}>
								<div>
									Export data updated from this date...
									{EditFormBase.boundTextboxValue('',
										this.state.minDate || '',
										(minDate) => this.setState({minDate}),
										'yyyy-mm-dd',
									)}
								</div>
								<div>
									...to this date (leave blank for current date)
									{EditFormBase.boundTextboxValue('',
										this.state.maxDate || '',
										(maxDate) => this.setState({maxDate}),
										'yyyy-mm-dd',
									)}
								</div>
								<div>
									{EditFormBase.boundCheckboxValue('Only records which were updated?',
										this.state.onlyUpdates,
										(onlyUpdates) => this.setState({onlyUpdates}),
										true,
									)}
								</div>
								<div>
									{EditFormBase.boundCheckboxValue('Only records with contact info',
										false,
										(onlyUpdates) => this.setState({onlyUpdates}),
										true,
									)}
								</div>
								<div>
									<button type="submit" onClick={(e) => this.submitSearch(e)}>
										Submit
									</button>
								</div>
							</Col>
							<Col sm={6}>
								<h3>Export history</h3>
								{this.renderHistory()}
							</Col>
						</Row>

						{this.state.isLoading && <div>
                            Please wait...
                        </div>}

						{this.state.searchRes.value.registrations && (
							<div>
								{this.state.isLoading ? <div>loading</div> : (
									<table>
										<thead>
										<tr>
											<th>Name</th>
											<th>Account #</th>
											<th>Zip</th>
											<th>Updated</th>
											<th>email</th>
											<th>wantEmail</th>
											<th>homePhone</th>
											<th>wantHomeCall</th>
											<th>workPhone</th>
											<th>wantWorkCall</th>
											<th>cellPhone</th>
											<th>wantCellCall</th>
											<th>wantCellSMS</th>
										</tr>
										</thead>
										<tbody>
										{this.state.searchRes.value.registrations.map((row) => (
											<tr key={(row.partitionKey || '') + (row.rowKey || '')}>
												<td>{row.accountName}</td>
												<td>{row.accountNumber}</td>
												<td>{row.zipCode}</td>
												<td>{row.cDate}</td>
												<td>{row.email}</td>
												<td>{row.wantEmail && 'yes'}</td>
												<td>{row.homePhone}</td>
												<td>{row.wantHomeCall && 'yes'}</td>
												<td>{row.workPhone}</td>
												<td>{row.wantWorkCall && 'yes'}</td>
												<td>{row.cellPhone}</td>
												<td>{row.wantCellCall && 'yes'}</td>
												<td>{row.wantCellSMS && 'yes'}</td>
											</tr>
										))}
										</tbody>
									</table>
								)}
							</div>
						)}
					</Container>
				</Page>
			</IndexLayout>
		);
	}

	private renderHistory() {
		if (!this.state.exportsRes) return false;
		if (!this.state.exportsRes.value) return false;
		return (
			<ul>
				{this.state.exportsRes.value.map((historyItem) => {

					return (
						<li key={historyItem.id}>
							Exported from {historyItem.fromDate} to {historyItem.toDate} on {historyItem.exportDate}
						</li>
					)
				})}
			</ul>
		);
	}
}

export default AdminExportPage
